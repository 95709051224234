import { isBrowser } from '@utils/isBrowser'

const pushToDataLayer = ({ event, ...data }: any) => {
  if (isBrowser && data) {
    if (window && window.dataLayer) {
      window.dataLayer.push({ event_params: null })
      window.dataLayer.push({
        event: event,
        event_params: data,
      })
    }
  }
}

export default pushToDataLayer
